import React from 'react';
import Layout from '../components/layout';
import * as styles from './notre-aventure.module.scss';
import '../components/layout.scss';
import SEO from '../components/seo';

const SocietePage = () => (
  <Layout>
    <SEO title="La société" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">L'aventure In-Print</h1>
            <p>Cedric et Lionel PORTA : codirigeants, frères & associés.</p>
          </div>
        </div>
        <div className="row mb0">
          <div className="col-lg-4">
            <div className={styles.dirImg} />
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className={styles.story}>
                <h2>In-Print ? 18 années menées tambour battant !</h2>
                <p>
                  Entrepreneurs dans l’âme, nous avons fait en 2007 le pari fou
                  de mutualiser nos compétences pour donner naissance à un
                  projet de vie : In-Print.
                </p>
                <p>
                  Partis d’une feuille blanche puis proposant sans cesse de
                  nouvelles solutions, nous avons progressivement bâti une offre
                  complète en matière d’impression de supports de communication.
                </p>
                <p>
                  Nous n’imaginions pas pouvoir constituer en 2025 une Team de
                  20 collaborateurs et réaliser un chiffre d’affaires de plus de 5
                  millions d’euros.
                </p>
                <p>
                  A présent, nous sommes fiers des relations de confiance nouées
                  avec nos clients qui ont amené la croissance soutenue
                  d’In-Print et la reconnaissance de son savoir-faire au niveau
                  national.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={` parallax ${styles.grandFormat}`}>
      <div className="container">
        <div className="row mb0">
          <div className="col-sm-12">
            <div className="card text-center bg-light-grey">
              <div className={styles.story}>
                <h2>Depuis 2019 ? Le spécialiste du Grand Format !</h2>
                <p>
                  Portée par des valeurs communes de professionnalisme, de
                  respect et de partage, notre équipe relève désormais le défi
                  de devenir un acteur majeur de l’impression Grand Format.
                </p>
                <p>
                  Dans ce but, nous renforçons nos moyens en investissant
                  massivement dans notre atelier. Grâce à des outils de commande
                  modernes et innovants, des traceurs numériques et des
                  matériels de découpe et de finition Grand Format, une gamme
                  plus large de produits est désormais proposée à nos clients
                  afin de répondre à leurs besoins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row mb0">
          <div className="col-lg-8">
            <div className="card">
              <div className={styles.story}>
                <h2>Et l’avenir ? Des idées plein la tête !</h2>
                <p>
                  La passion de notre métier est notre leitmotiv. Nos clients
                  nous font évoluer et nous avons à coeur de les accompagner car
                  chacun de leur projet est unique. Pour cela, nous gardons
                  comme cap la maîtrise de la chaîne graphique et un véritable
                  engagement en matière de qualité de service.
                </p>
                <p>
                  Nous continuerons ainsi avec enthousiasme à nous appuyer sur
                  ce qui nous porte : l’envie constante de progresser et une
                  vision stratégique à long terme qui place le client au coeur
                  de nos orientations.
                </p>
                <p>Ensemble, regardons loin vers un avenir Printastic !</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className={styles.avenirImg} />
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default SocietePage;
